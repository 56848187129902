import React, {useState} from "react";
const ChamberReviews = () =>{

    const [isMore, setMore] = useState(false)
    const handleClick = () => {
        return setMore(!isMore)
    }
        
    const reviewsChamb = [
        {
            name:"Wade Smith",
            date:"04/22/2014",
            text:"Very professional. Jose does some amazing work with trees. Seems as if he is one with the tree. Will definitely call again to do more work for me. Thanks again!!",
            location:"Huntsville, AL",
        },
        {
            name:"Upendra Shah",
            date:"06/15/2014",
            text:"Jose did very good job. He was very cooperative. He cleaned up very nicely.",
            location:"Huntsville, AL",
        },
        {
            name:"Jim Brown",
            date:"07/01/2014",
            text:"Great work at a fair price. Fast and good clean up. Highly recommended. Thanks for a good job.",
            location:"Harvest",
        },
        {
            name:"Nancy L. Smith",
            date:"05/12/2015",
            text:"Jose did an excellent job at a reasonable price for me last fall. I meant to do a review right away but forgot. I thought of him again because I need more work and I would definitely call him again.",
            location:"Huntsville, AL",
        },
        {
            name:"Dan",
            date:"09/08/2015",
            text:"Jose and crew did a great job removing a large tree for me, very experienced doing tree work! Great price for all of the work that they did. I would definitely recommend and hire them again!",
            location:"Huntsville, AL",
        },
        {
            name:"Laura Dennis",
            date:"10/02/2015",
            text:"Jose did a wonderful job on my trees! He was very professional. Showed up early got his work done and left the job site tidy at the end of each day! I will recommend him to friends and neighbors who need tree work!",
            location:"Huntsville",
        },
        {
            name:"Dave McGhee",
            date:"03/07/2017",
            text:"Jose and his crew did a great job removing a 30 year old Sycamore tree from some tight quarters. Very professional and respectful of property. Very reasonable price as well. Definitely recommend their work.",
            location:"Huntsville",
        },
        {
            name:"Susan Gahagan",
            date:"07/06/2017",
            text:"I was lucky enough to talk with Jose when he did an excellent job on my neighbors trees. He is very courteous, reasonably priced and professional. The job was done as requested and then some (he also took the time to fix my annoying gate). When he was finished everything had been cleaned up and asked if everything was ok. I highly recommend his company and would use him again. Thanks!",
            location:"Huntsville",
        },
        {
            name:"Gene and Ann Marie Lang",
            date:"07/21/2017",
            text:"We are extremely pleased with the quality work and professionalism that Jose and his crew exhibited today. They were prompt, cleaned up (took away more than what they created) and did so quickly and at a good price. I have already recommended Mejicanos Tree Care to several friends. You won't go wrong with this company.",
            location:"Huntsville",
        },
        {
            name:"Marc Spencer",
            date:"08/11/2017",
            text:"Jose did an outstanding job removing a very large magnolia tree for us. He was meticulous, and left the yard neat, clean and ready for grass seed. Many thanks, Jose! I'll call you again in the future!",
            location:"Huntsville",
        },
        {
            name:"Lisa Saccuzzo",
            date:"2/29/2020",
            text:"Jose Mejicanos (256) 585-1512 Jose is professional and does exceptional quality work. We've been very pleased with his tree removal service. We've recommended him to several friends. They've also been happy with his work. Jose is the owner, licensed, fully insured and is reasonably priced compared to others.",
            location:"Huntsville",
        },
    ]

    return(
        <section className="w-full py-20">
            <h2 className="text-center">Our Reviews</h2>
                
                {
                    isMore?
                    reviewsChamb.map((item, index) =>{
                        return(
                            <section key={index} className="flex flex-col border-b-2 border-gray-300 w-[95%] min-h-[100px] mx-auto">
                            <div className="flex justify-between p-5">
                                <div className="flex">
                                <img
                                src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                                alt="userphotodeatult"
                                 className="w-[50px] shadow-md rounded-[50%]"/>
                                <div className="ml-3 flex flex-col">
                                    <span className="font-medium">{item.name}</span>
                                    <span className="font-normal">{item.location}</span>
                                    {/* <Stars 
                                    score={item.stars}
                                    /> */}
                                </div> 
                                </div>
                                <span className="">{item.date}</span>
    
                            </div>
                            <div className="p-5">
                                <p>{item.text}</p>
                            </div>

                        </section>
                        )
                    })
                    :
                    reviewsChamb.slice(0,4).map((item, index) =>{
                        return(
                            <section key={index} className="flex flex-col border-b-2 border-gray-300 w-[95%] min-h-[100px] mx-auto">
                            <div className="flex justify-between p-5">
                                <div className="flex">
                                <img
                                src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                                alt="userphotodeatult"
                                 className="w-[50px] shadow-md rounded-[50%]"/>
                                <div className="ml-3 flex flex-col">
                                    <span className="font-medium" >{item.name}</span>
                                    <span className="font-normal">{item.location}</span>
                                    {/* <Stars 
                                    score={item.stars}
                                    /> */}
                                </div> 
                                </div>
                                <span className="">{item.date}</span>
    
                            </div>
                            <div className="p-5">
                                <p>{item.text}</p>
                            </div>
                        </section>
                        )
                    })
                }


                <button className="bg-red-500 p-5 rounded-lg mx-auto block mt-20 text-white" onClick={handleClick} >
                        {
                            isMore?
                            "Show Less"
                            :
                            "Show More"
                        }
                </button>
        </section> 
    );

}

export default ChamberReviews